var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Admin" } }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Admin")
                  ]),
                  _c(
                    "gov-tabs",
                    { attrs: { tabs: _vm.tabs, bar: "", panel: false } },
                    [
                      _c("gov-section-break", { attrs: { size: "m" } }),
                      _c("router-view")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }